<template>
  <section>
    <h2 class="uppercase pl-4 md:pl-28 pt-4 text-gray-600">
      Sie sind hier / <a style="color: #f27405" href="/home">Beroma Gmbh</a> /
      Jalousien / Plisee <br />
    </h2>
    <article class="container mx-auto pl-2 pt-2 lg:p-32">
      <h2 class="text-4xl md:text-6xl pb-10" style="color: #f27405">
        Germania - Plisee
      </h2>
      <ul class="grid grid-cols-2">
        <li class="col-span-full md:col-span-1">
          <productslide v-bind:slides="slides"></productslide>
        </li>

        <li class="col-span-full md:col-span-1">
          <h3 class="text-2xl p-5 md:pl-10 md:pb-3" style="color: #033859">
            Visuelle Vielseitigkeit
          </h3>
          <p class="w-full px-5 md:pl-10 md:pb-3">
            Moderner Sonnenschutz ist eine dekorative Einrichtungskomponente,
            die Wohntrends widerspiegelt.<br /><br />
            Die Vielfalt an Fensterformen und die unter-schiedlichen Ansprüche
            beim Sicht-, Blick- und Sonnenschutz in Kombination mit dem
            persönlichen Stil des Kunden spielten eine große Rolle bei der
            Entwicklung der Plissee-Kollektion.<br /><br />
            Von frischem minimalistischen Weiß bis hin zu farbenfrohen und
            trendigen Designs : Plissees können ein diskreter und raffinierter
            Rahmen und eine besondere Gestaltungsmöglichkeit für Ihr Zuhause
            sein.
          </p>
        </li>
        <li class="col-span-full">
          <h3 class="text-2xl p-5 md:pb-3" style="color: #033859">
            Nachhaltige Stoffe
          </h3>
          <p class="w-full px-5 md:pb-3">
            Die neue Plissee-Kollektion bietet mehr als neunzig schöne und
            nachhaltige Stoffe, die Cradle to Cradle Certified ™ sind. Diese
            Zertifizierung gibt Ihnen die Möglichkeit, eine verantwortungsvolle
            Auswahl für Ihr Zuhause und Ihre Familie zu treffen.
          </p>
        </li>
        <li class="col-span-full">
          <h3 class="text-2xl p-5 md:pb-3" style="color: #033859">
            Geeignet für verschiedenste Fensterformen
          </h3>
          <p class="w-full px-5 md:pb-3">
            Plissees sind in einer breiten Palette an ausgezeichneten
            Materialien, mit hochwertigen Strukturen und Farben in vier
            Lichtdurchlässigkeitsgraden erhältlich. Es stehen zwei Faltenbreiten
            zur Verfügung. Dadurch können Sie Fenstern oder ganzen
            Fensterfronten eine spezielle Note verleihen.
          </p>
        </li>
      </ul>
      <div class="p-4">
        <div class="text-4xl pt-10 pb-3" style="color: #033859">Eckdaten</div>
        <tabs :mode="mode">
          <tab title="PowerView">
            <p class="text-lg">
              <span class="font-bold">Motorisierung</span> <br />
              Was wäre, wenn Ihr Sonnenschutz Gedanken lesen könnte? Wenn er
              wüsste, wie Ihr Tag aussehen wird, und wenn er in der Lage wäre,
              ihn noch schöner werden zu lassen?<br /><br />
              Dafür gibt es die PowerView®- Motorisierung – ein kabelloses
              Motorisierungssystem, mit dem Sie alle Sonnenschutz-Produkte per
              Smartphone, Tablet oder Fernsteuerung bedienen können. Die
              Einbindung in ein Smart Home-System ist kein Problem.
            </p>
          </tab>
          <tab title="Extras">
            <p class="text-lg">
              Germania - Plisee ist in verschiedenen Farben und Ausführungen
              erhältich. <br />
              Besuchen Sie unsere Ausstellung und lassen Sie sich beraten.
            </p>
          </tab>
        </tabs>
      </div>
    </article>
  </section>
</template>
<script>
import Tab from "../components/Tab";
import Tabs from "../components/Tabs";
import productslide from "../components/product-slide";

export default {
  components: {
    Tab,
    Tabs,
    productslide,
  },
  data() {
    return {
      slides: [
        {
          image: require("@/assets/img/germania/plisee/plisee-2.webp"),
          alt: "germania plisee",
        },
        {
          image: require("@/assets/img/germania/plisee/plisee-3.webp"),
          alt: "plissee rot esszimmer",
        },
        {
          image: require("@/assets/img/germania/plisee/plisee-4.webp"),
          alt: "plisee außen sichtschutz",
        },
        {
          image: require("@/assets/img/germania/plisee/plisee-5.webp"),
          alt: "plisee wohnzimmer",
        },
        {
          image: require("@/assets/img/germania/plisee/plisee-6.webp"),
          alt: "plisee schlafzimmer",
        },
        {
          image: require("@/assets/img/germania/plisee/plisee-7.webp"),
          alt: "plisee in wohnzimmer",
        },
      ],
    };
  },
};
</script>
